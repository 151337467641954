<template>
  <div v-if="products.length" class="catalog-banner__list">
    <banner-card
      v-for="(item, index) in getMobileProducts()"
      :key="index"
      :item="item"
    />
  </div>
</template>

<script>
export default {
  name: "BannerList",
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  components: {
    BannerCard: () => import("@/components/banner/BannerCard.vue"),
  },
  methods: {
    getMobileProducts() {
      if (this.$isMobile()) {
        return this.products.slice(0, 5);
      } else {
        return this.products;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
